import React from "react";
import { placeholder } from "../../Library/icons";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../assets/css/NewsEvents.css";
import "../../assets/css/Links.css";
import { colors } from "../../Library/colors";
import { fontFamilyRegular } from "../../Library/styles";
import GeneralTitle from "../GlobalComponent/GeneralTitle";
import "../../assets/css/colors.css";
import "../../assets/css/Global.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const NewsEvents = ({ news }) => {
  const imageUrl = useSelector((state) => state.imageUrl);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4.5,
    },
    tablet: {
      breakpoint: { max: 1400, min: 1024 },
      items: 3.5,
    },
    mobile: {
      breakpoint: { max: 1023, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 767, min: 569 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 568, min: 200 },
      items: 1,
    },
  };
  return (
    <div className=" d-flex flex-column">
      <div className="ps-5">
        <GeneralTitle Title={"NEWS & EVENTS"} />
      </div>

      <Carousel
        responsive={responsive}
        containerClass="carousel-container"
        itemClass="CarouselItem"
      >
        {news.map((item, index) => {
          return (
            <Link to={"/singleEvent/" + item.id + "/" + item.title}>
            <div className="card me-2" key={index}>
              <div className="card-body p-3">
                <div style={{ width: "100%", height: 250 }}>
                  <img
                    src={imageUrl + item.image}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <div className="cardTitle my-2 color-darkgrey defaultFont">
                  {item.title}
                </div>
                <div className="cardDesctiption color-grey defaultFont">
                  {item.shortDescription}
                </div>
                <div className="mt-2 d-flex align-items-center ">
                  <div className="linkDescription color-darkgrey defaultFont">
                    {item.buttonTitle}
                  </div>
                  <div className="ms-2">
                    <i class="fa fa-light fa-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>
            </Link>
            
          );
        })}

        <div className=""></div>
      </Carousel>
    </div>
  );
};

export default NewsEvents;
