import React, { useEffect, useState } from "react";
import Footer from "../Components/GlobalComponent/Footer";
import HeaderDescription from "../Components/GlobalComponent/HeaderDescription";
import NewsTitle from "../Components/GlobalComponent/NewsTitle";
import "../assets/css/Aboutus.css";
import { colors } from "../Library/colors";
import { city, docter, productImage } from "../Library/icons";
import { fontFamilyRegular } from "../Library/styles";
import "../assets/css/colors.css";
import "../assets/css/Global.css";
import { useLocation } from "react-router-dom";
import { scrollToTop } from "../Library/functions";
import Loading from "../Components/GlobalComponent/Loading";
import Navbar from "../Components/GlobalComponent/Navbar";
import { get } from "../api/api";
import { getAbout, getHomePage, getImpact } from "../api/url";
import { useDispatch, useSelector } from "react-redux";
import { ABOUTPAGE, HOMEPAGE } from "../Redux/actionTypes";
import { Helmet } from "react-helmet-async";

const Aboutus = () => {
  const params = useLocation();
  const [loaded, setloaded] = useState(false);
  const dispatch = useDispatch();
  const aboutPage = useSelector((state) => state.aboutPage);
  const imageUrl = useSelector((state) => state.imageUrl);

  const getAboutInfo = async () => {
    // setloaded(true)
    const home = await get(getAbout()).then((resp) => {
      console.log(resp);
      if (resp.error) {
      } else {
        dispatch({ type: ABOUTPAGE, value: resp.message.data });
        setloaded(true);
      }
    });
  };
  useEffect(() => {
    getAboutInfo();
  }, []);
  if (loaded) {
    return (
      <>
        <Helmet>
          <title>About Us</title>
        </Helmet>
        <Navbar />
        <div
          className="imagecontainer3"
          style={{
            backgroundImage: `url(${
              imageUrl + aboutPage.pages.slider_about.image
            })`,
          }}
        >
          <div
            className="phoneImage"
            style={{
              backgroundImage: `url(${
                imageUrl + aboutPage.pages.slider_about.image
              })`,
            }}
          ></div>
          <HeaderDescription
            leftBorder={true}
            rightBorder={false}
            topBorder={false}
            bottomBorder={false}
            buttonDisplay={false}
            rules={[]}
            firstTitle=""
            secondTitle={aboutPage.pages.slider_about.title}
            description={aboutPage.pages.slider_about.shortDescription}
          />
        </div>
        <div className="d-flex justify-content-center">
          <NewsTitle title={aboutPage.pages.content_about.title} />
        </div>
        <div className="HeaderDescription container color-grey defaultFont">
          {aboutPage.pages.content_about.shortDescription}
        </div>
        <div className="container my-5">
          <div className="row">
            <div className="col-12 col-lg-6 p-2 p-sm-5">
              <div className="topRectangle"></div>
              <div className="storyText color-grey defaultFont">
                {aboutPage.pages.content_about.content}
              </div>
            </div>
            {/* <div className="col-12 col-lg-6  p-2 p-sm-5">
              <div className="topRectangle"></div>
              <div className="storyText color-grey defaultFont">
                We confidently look to the future, trusting that our people, our
                technology, and our solutions not only solve the problems of
                today, but set society up for success tomorrow.
              </div>
            </div> */}
          </div>
        </div>
        {aboutPage.missions.map((item, index) => {
          if (index % 2 == 0) {
            return (
              <div className="container py-5" key={index}>
                <div
                  className="ourCultureContainer d-flex justify-content-center align-items-center p-0 p-sm-5"
                  style={{
                    backgroundImage: `url(${imageUrl + item.image})`,
                  }}
                >
                  <div className="containerBackground background-lightblue"></div>
                  <div
                    className="p-3 p-sm-5 background-lightwhite"
                    style={{
                      width: "80%",
                      zIndex: 2,
                    }}
                  >
                    <div className="ourCultureTitle mb-2 color-grey defaultFont">
                      {item.title}
                    </div>
                    <div className="ourCultureDescription color-grey defaultFont">
                      {item.shortDescription}
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div className="container p-0" key={index}>
                <div
                  className="my-5 m-0 row EachItem"
                  style={{ backgroundImage: `url(${imageUrl + item.image})` }}
                >
                  <div
                    className="phoneImage"
                    style={{
                      backgroundImage: `url(${imageUrl + item.image})`,
                    }}
                  ></div>
                  <div className="col-12 p-0 d-flex justify-content-center  justify-content-md-end align-items-end">
                    <div className="p-0">
                      <HeaderDescription
                        leftBorder={false}
                        rightBorder={true}
                        topBorder={false}
                        bottomBorder={false}
                        buttonDisplay={false}
                        rules={[]}
                        firstTitle=""
                        secondTitle={item.title}
                        description={item.shortDescription}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        })}

        <div className="mt-5">
          <Footer />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
};

export default Aboutus;
