import React, { useEffect, useState } from "react";
import HeaderDescription from "../Components/GlobalComponent/HeaderDescription";
import NewsTitle from "../Components/GlobalComponent/NewsTitle";
import OtherNews from "../Components/News/OtherNews";
import UpcomingEvents from "../Components/News/UpcomingEvents";
import Footer from "../Components/GlobalComponent/Footer";
import "../assets/css/News.css";
import { colors } from "../Library/colors";
import { placeholder, productImage } from "../Library/icons";
import { fontFamilyRegular } from "../Library/styles";
import { useLocation } from "react-router-dom";
import { scrollToTop } from "../Library/functions";
import Loading from "../Components/GlobalComponent/Loading";
import Navbar from "../Components/GlobalComponent/Navbar";
import { get } from "../api/api";
import { getNews } from "../api/url";
import { useDispatch, useSelector } from "react-redux";
import { NEWSPAGE } from "../Redux/actionTypes";
import { Helmet } from "react-helmet-async";
const News = () => {
  const params = useLocation();
  const [loaded, setloaded] = useState(false);
  const dispatch = useDispatch();
  const newsPage = useSelector((state) => state.newsPage);
  const imageUrl = useSelector((state) => state.imageUrl);
  const getNewsInfo = async () => {
    const news = await get(getNews()).then((resp) => {
      console.log(resp);
      if (resp.error) {
      } else {
        dispatch({ type: NEWSPAGE, value: resp.message.data });
        setloaded(true);
      }
    });
  };
  useEffect(() => {
    getNewsInfo();
  }, []);
  if (loaded) {
    return (
      <>
        <Helmet>
          <title>News</title>
        </Helmet>
        <Navbar />
        <div
          className="imageContainer4"
          style={{
            backgroundImage: `url(${
              imageUrl + newsPage.pages.slider_news.image
            })`,
          }}
        >
          <div
            className="phoneImage"
            style={{
              backgroundImage: `url(${
                imageUrl + newsPage.pages.slider_news.image
              })`,
            }}
          ></div>
          <HeaderDescription
            leftBorder={true}
            rightBorder={false}
            topBorder={false}
            bottomBorder={false}
            rules={[]}
            firstTitle=""
            secondTitle={newsPage.pages.slider_news.title}
            description={newsPage.pages.slider_news.shortDescription}
          />
        </div>
        <UpcomingEvents Upcoming={newsPage.events} />
        <OtherNews newNews={newsPage.news} />
        <div className="pt-5">
          <Footer />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
};

export default News;
