import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { get } from "../api/api";
import {
  getHomePage,
  getSingleCapabilities,
  getSingleMarket,
} from "../api/url";
import Footer from "../Components/GlobalComponent/Footer";
import HeaderDescription from "../Components/GlobalComponent/HeaderDescription";
import Loading from "../Components/GlobalComponent/Loading";
import Navbar from "../Components/GlobalComponent/Navbar";
import PageHeader from "../Components/GlobalComponent/PageHeader";
import { scrollToTop } from "../Library/functions";
import { army, city, docter, productImage, us } from "../Library/icons";
const Capabilities = () => {
  const params = useParams();
  const state=useLocation()
  const [loaded, setloaded] = useState(false);
  const [marketInfo, setmarketInfo] = useState({});
  const imageUrl = useSelector((state) => state.imageUrl);
  const getmarketInfo = async () => {
    setloaded(false);
    const home = await get(getSingleCapabilities(params.id)).then((resp) => {
      // console.log(resp);
      if (resp.error) {
      } else {
        setmarketInfo(resp.message.data.capability);
        setloaded(true);
      }
    });
  };
  useEffect(() => {
    getmarketInfo();
  }, []);
  useEffect(() => {
    getmarketInfo();
  }, [params.id]);
  if (loaded) {
    return (
      <>
        <Helmet>
          
          <meta charset="utf-8" />
          <meta http-equiv="x-ua-compatible" content="ie=edge" />
          <meta name="description" content={marketInfo.shortDescription} />
          <meta name="keywords" content={marketInfo.title} />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0 , maximum-scale=1, minimum-scale=1, user-scalable=no"
          />

        <meta property="og:title" content={marketInfo.shortDescription} />
        <meta property="og:url" content={state.pathname}/>
        <meta property="og:description" content={marketInfo.shortDescription}/>
        <meta property="og:image" content={imageUrl +marketInfo.image}/>
        <meta property="og:type" content="article"/>
        <meta property="og:site_name" content={marketInfo.title}/>
          <title>{marketInfo.title}</title>
        </Helmet>
        <Navbar />
        <PageHeader
          image={marketInfo.image}
          title={marketInfo.title}
          description={marketInfo.shortDescription}
          content={marketInfo.content}
        />
        <div style={{ overflowX: "hidden" }} className="mx-sm-5 m-0">
          <div className="container p-0">
            {marketInfo.features.map((item, index) => {
              if (index % 2 == 0) {
                return (
                  <div
                    className="my-5  m-0 row EachItem"
                    key={index}
                    style={{ backgroundImage: `url(${imageUrl + item.image})` }}
                  >
                    <div
                      className="phoneImage"
                      style={{
                        backgroundImage: `url(${imageUrl + item.image})`,
                      }}
                    ></div>
                    <div className="col-12 p-0 d-flex justify-content-center  justify-content-md-end align-items-end">
                      <div className="p-0">
                        <HeaderDescription
                          leftBorder={true}
                          rightBorder={false}
                          topBorder={false}
                          bottomBorder={false}
                          buttonDisplay={false}
                          rulesDisplay={false}
                          rules={[]}
                          firstTitle=""
                          secondTitle={item.title}
                          description={item.content}
                        />
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    className="my-5  m-0 row EachItem"
                    key={index}
                    style={{
                      backgroundPositionX: "right",
                      backgroundImage: `url(${imageUrl + item.image})`,
                    }}
                  >
                    <div
                      className="phoneImage"
                      style={{
                        backgroundImage: `url(${imageUrl + item.image})`,
                      }}
                    ></div>
                    <div className="col-12 p-0 d-flex justify-content-center    justify-content-md-start align-items-end">
                      <div>
                        <HeaderDescription
                          leftBorder={false}
                          rightBorder={true}
                          topBorder={false}
                          bottomBorder={false}
                          buttonDisplay={false}
                          // rulesDisplay={item.feature.length == 0 || item.feature ==undefined? false : true}
                          rules={[]}
                          firstTitle=""
                          secondTitle={item.title}
                          description={item.content}
                        />
                      </div>
                    </div>
                  </div>
                );
              }
            })}

            {/* <div
              className="my-5  m-0 row EachItem"
              style={{
                backgroundPositionX: "right",
                backgroundSize: "60% 100%",
                height: 900,
                backgroundImage: `url(${us})`,
              }}
            >
              <div
                className="phoneImage"
                style={{
                  backgroundImage: `url(${us})`,
                }}
              ></div>
              <div className="col-12 p-0 d-flex  justify-content-center  justify-content-md-start align-items-center">
                <div>
                  <HeaderDescription
                    leftBorder={false}
                    rightBorder={true}
                    topBorder={false}
                    bottomBorder={false}
                    buttonDisplay={false}
                    rules={[]}
                    rulesDisplay={true}
                    firstTitle="STATE AND LOCAL GOVERNMENT"
                    secondTitle="Delivering for every 
                  level of government.
                "
                    description="We partner with state and local governments across the United States to identify and deliver the right infrastructure, business solutions, and digital-transformation services to meet their goals and better serve their citizens.
  
                  "
                  />
                </div>
              </div>
            </div> */}
          </div>

          <div className="py-5 "></div>
        </div>
        <Footer />
      </>
    );
  } else {
    return <Loading />;
  }
};

export default Capabilities;
