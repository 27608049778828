const navimage = require("../assets/icons/img.jpg");
const Logo = require("../assets/icons/websiteLogo.png");
const placeholder = require("../assets/icons/placeholder.png");
const placeholder2 = require("../assets/icons/placeholder2.png");
const placeholder3 = require("../assets/icons/placeholder3.png");
const productImage = require("../assets/icons/productImage.png");

const army = require("../assets/icons/army.png");
const city = require("../assets/icons/city.png");
const docter = require("../assets/icons/docter.png");
const pc = require("../assets/icons/pc.png");
const us = require("../assets/icons/us.png");

export {
  navimage,
  Logo,
  placeholder,
  placeholder2,
  placeholder3,
  productImage,
  army,
  city,
  docter,
  pc,
  us,
};
