import React from "react";
import { placeholder } from "../../Library/icons";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { colors } from "../../Library/colors";
import { fontFamilyRegular } from "../../Library/styles";
import GeneralTitle from "../GlobalComponent/GeneralTitle";
import "../../assets/css/colors.css";
import "../../assets/css/Global.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Markets = ({ markets }) => {
  const imageUrl = useSelector((state) => state.imageUrl);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1400, min: 1024 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 1023, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 767, min: 569 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 568, min: 200 },
      items: 1,
    },
  };
  return (
    <div className="ps-0 ps-xl-5 d-flex flex-column my-5">
      <div className="ps-5">
        <GeneralTitle Title={"MARKETS"} />
      </div>

      <Carousel
        responsive={responsive}
        containerClass="carousel-container"
        itemClass="CarouselItem"
      >
        {markets.map((item, index) => {
          return (
            <Link to={"/market/" + item.id + "/" + item.title}>
              <div className="card  hovereffect p-3" key={index}>
                <div className="card-body  ">
                  <div className="imagecard">
                    <img
                      src={imageUrl + item.image}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div className="descriptionhover">
                    <div className="cardTitle my-2 color-darkgrey defaultFont ">
                      {item.title}
                    </div>
                    <div className="cardDesctiption color-grey defaultFont">
                      {item.shortDescription}
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
        <div className=""></div>
      </Carousel>
    </div>
  );
};

export default Markets;
