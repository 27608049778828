import "../../assets/css/Slider.css";
import {
  placeholder,
  placeholder2,
  placeholder3,
  productImage,
} from "../../Library/icons";
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Parallax, Pagination, Navigation } from "swiper";
import { useSelector } from "react-redux";

const Slider = ({ slider }) => {
  const imageUrl = useSelector((state) => state.imageUrl);
  // console.log(slider, "Slider");
  const [hover,sethover]=useState(false)
 
  return (
    <>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        speed={600}
        parallax={false}
        pagination={{
          clickable: true,
        }}
        loop={true}
        navigation={true}
        modules={[Parallax, Pagination, Navigation]}
        className="mySwiper"
      >
        {slider.map((item,index) => {
          return (
            <SwiperSlide onMouseLeave={()=>{sethover(false)}} onMouseEnter={()=>{sethover(true)}} key={index}>
              <img src={hover?imageUrl+item.additionalImage: imageUrl + item.image} />
              <div className="swiper-slide-content2" style={{left:index ==2?'60%':'10%',top:index ==2?'40%':'22%'}}>
                <div className="title imageslider" style={{display:hover?'none':'block'}} data-swiper-parallax="-300">
                  {item.title}
                </div>
                <div className="title imageslider" style={{display:hover?'block':'none'}} data-swiper-parallax="-300">
                  {item.shortDescription}
                </div>

                <div className="text onhoverslider" data-swiper-parallax="-100">
                  {/* <p>{item.shortDescription}</p> */}
                  <div className="buttonslider">
                    Learn More
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default Slider;
