import React, { useEffect, useState } from "react";
import "../../assets/css/Navbar.css";
import { colors } from "../../Library/colors";
import { navimage, Logo } from "../../Library/icons";
import "../../assets/css/colors.css";
import "../../assets/css/Global.css";
import { Link, useNavigate } from "react-router-dom";
import { get } from "../../api/api";
import { getFooter } from "../../api/url";
import { useDispatch, useSelector } from "react-redux";
import { CAPABILITIES, FOOTERITEMS, MARKETS } from "../../Redux/actionTypes";
const Navbar = () => {
  const navigate = useNavigate();
  // const [markets, setmarkets] = useState([]);
  // const [capabilities, setcapabilities] = useState([]);
  const markets = useSelector((state) => state.markets);
  const capabilities = useSelector((state) => state.capabilities);

  const dispatch = useDispatch();
  const navbarItems = useSelector((state) => state.navbarItems);
  const getFooterInfo = async () => {
    if (markets.length == 0 || capabilities.length == 0) {
      let info = await get(getFooter()).then((resp) => {
        if (resp.error) {
        } else {
          // dispatch({ type: FOOTERITEMS, value: resp.message.data });
          dispatch({ type: MARKETS, value: resp.message.data.markets });
          dispatch({
            type: CAPABILITIES,
            value: resp.message.data.capabilities,
          });
          // setmarkets(resp.message.data.markets);
          // setcapabilities(resp.message.data.capabilities);
        }
      });
    }
  };
  useEffect(() => {
    getFooterInfo();
  }, []);
  return (
    <nav className="background-white ">
      <div className="wrapper align-items-center align-items-lg-start">
        <div
          className="logo"
          onClick={() => {
            navigate("/home");
          }}
        >
          <img src={Logo} />
        </div>

        <div
          className="d-block d-lg-flex flex-column justify-content-between navLinkContainer "
          style={{ width: "85%" }}
        >
          <input type="radio" name="slider" id="menu-btn" />
          <input type="radio" name="slider" id="close-btn" />
          <ul className="nav-links d-block d-lg-flex justify-content-between align-items-center">
            <label for="close-btn" className="btn close-btn">
              <i className="fas fa-times"></i>
            </label>
            <div
              className=" d-none d-lg-block background-lightblue"
              style={{
                width: 5,
                height: 5,
                borderRadius: "50%",
              }}
            ></div>
            <li>
              <div className="desktop-item navbarlinks">MARKETS</div>
              <input type="checkbox" id="showMega" />
              <label for="showMega" className="mobile-item">
                MARKETS
              </label>
              <div className="mega-box p-0 " style={{ zIndex: 2 }}>
                <div className="content p-0 p-lg-5 px-0 px-lg-3 row">
                  <div className="col-12 col-lg-5">
                    <div className="navbar-phone">
                      <div className=" navbar-image d-flex">
                        <div className="background-lightblue background-lightblueLine"></div>
                        <img src={navimage} alt="" style={{ width: "100%" }} />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-7 p-0 p-lg-auto">
                    <div className="d-flex nav-flex ">
                      <div className="row">
                        <div className="col-12  p-0">
                          <ul className="mega-links ms-3">
                            {markets.map((item, index) => (
                              <li className="col-12 col-lg-4" key={index}>
                                <Link
                                  to={"/market/" + item.id + "/" + item.title}
                                >
                                  <div className="navbarlinks">
                                    {item.title}
                                  </div>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <div
              className=" d-none d-lg-block background-lightblue"
              style={{
                width: 5,
                height: 5,
                borderRadius: "50%",
              }}
            ></div>
            <li>
              <div className="desktop-item navbarlinks">CAPABILITIES</div>
              <input type="checkbox" id="showMega2" />
              <label for="showMega2" className="mobile-item">
                CAPABILITIES
              </label>
              <div className="mega-box p-0 " style={{ zIndex: 2 }}>
                <div className="content p-0 p-lg-5 px-0 px-lg-3 row">
                  <div className="col-12 col-lg-5">
                    <div className="navbar-phone">
                      <div className=" navbar-image d-flex">
                        <div className="background-lightblue background-lightblueLine"></div>
                        <img src={navimage} alt="" style={{ width: "100%" }} />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-7 p-0 p-lg-auto">
                    <div className="d-flex nav-flex ">
                      <div className="row">
                        <div className="col-12  p-0">
                          <ul className="mega-links ms-3">
                            {capabilities.map((item, index) => (
                              <li className="col-12 col-lg-4" key={index}>
                                <Link
                                  to={
                                    "/capabilities/" +
                                    item.id +
                                    "/" +
                                    item.title
                                  }
                                >
                                  <div className="navbarlinks">
                                    {item.title}
                                  </div>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <div
              className=" d-none d-lg-block background-lightblue"
              style={{
                width: 5,
                height: 5,
                borderRadius: "50%",
              }}
            ></div>
            <li>
              <Link to={"/about"}>
                <div className="navbarlinks">ABOUT</div>
              </Link>
            </li>
            <div
              className=" d-none d-lg-block background-lightblue"
              style={{
                width: 5,
                height: 5,
                borderRadius: "50%",
              }}
            ></div>
            <li>
              <Link to={"/contact"}>
                <div className="navbarlinks">CONTACT</div>
              </Link>
            </li>
            <div
              className=" d-none d-lg-block background-lightblue"
              style={{
                width: 5,
                height: 5,
                borderRadius: "50%",
              }}
            ></div>
            {/* <li>
              <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAACB0lEQVR4nO2WzWoUQRDHm2i8+HGL4Cus4mVxqjcIq2c9mJDkFUKCmpinCHmN+AG+gaepGhVD2EtiIBGNV4luqgY3Bz11qJnpVS/rdO+yCKagYGC6+lfdVfWfMebM/hVr7LkLFvMFIHkOyAeW5ERdn4Hkmb7TNSOFAsqsRT60JG6QA/EnizIzPNG5CUDe6G+OsgPIj5Os27j56stF9Vuvj68D8gqQ7P5Kgtc1doiTcgEF4h+W8sWBm2mSKS+Vayt4HFRm+9CM27XjUrnj4a1MHkQ0Elc1zRfDk+blKumPzY6brB1oMV/wNY2p1fxLdw5I3useSSZzAWB5UYBTfhQK7e9BslqcGuVpAJg/lNl2G7Fg7fYSzAe1g4Ckp0Ht9OhSLHj6zbfLZZ2lN1ZwstW9Egy2I7jqVnp8w3d27SBQ7S3rsxILBpS1ajI2g8cJSHZjx8mi7BUigvl8kICACr7CU14KBVvih+Vp+bCduvNhwSgzXjJVBuvGJSh3LfLPUjL5fmjShanQ9+HIy3qFZtD16kkrKCAftd72rpooc27Cw6uaqwyuqjjoqKkX3ZvJE19TD/Xr4+HGGP3K6Fj87UfAIn9OiO8pzGv10PBmx02q4Kv2Asl+JTInRUIom9q9vzfSSOGhdjv7PuX/TDTZJn29Zv4LeOuPa+ftsYE93CJ3LMm7sYLPzFR2ChPgE5T+q/GtAAAAAElFTkSuQmCC" />
            </li> */}
          </ul>
          <div className="d-flex align-items-center justify-content-end">
            <label for="menu-btn" className="btn menu-btn tex">
              <i className="fas fa-bars"></i>
            </label>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
