import React from "react";
import { colors } from "../../Library/colors";
import { pc, placeholder } from "../../Library/icons";
import { fontFamilyRegular } from "../../Library/styles";
import "../../assets/css/News.css";
import "../../assets/css/Global.css";
import "../../assets/css/colors.css";
import { Link, useNavigate } from "react-router-dom";

const EachNews = ({
  imageDisplay,
  title,
  description,
  buttonTitle,
  id,
  event,
}) => {
  const navigate = useNavigate();

  return (
    <div className="col-12 col-md-6 col-lg-4 p-2 ">
      <Link to={"/singleEvent/" + id + "/" + title}>
        <div className=" d-flex flex-column p-3 background-whitegrey ">
          <img
            style={{
              width: "100%",
              height: 200,
              display: imageDisplay ? "flex" : "none",
            }}
            src={pc}
          />
          <div className="itemTitle my-2 color-grey defaultFont">{title}</div>
          <div className="itemDescription color-grey defaultFont ">
            {description}
          </div>
          <div className=" my-2">
            <div className="d-flex align-items-center ">
              <div className="itemButton color-grey defaultFont">
                {buttonTitle}
              </div>
              <div className="ms-4">
                <i class="fa fa-light fa-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default EachNews;
