import React from "react";
import HeaderDescription from "../GlobalComponent/HeaderDescription";
import NewsTitle from "../GlobalComponent/NewsTitle";
import EachNews from "./EachNews";

const UpcomingEvents = ({ Upcoming }) => {
  return (
    <>
      <div className="d-flex justify-content-center">
        <NewsTitle title="Upcoming Events" />
      </div>
      <div className="container">
        <div className="row">
          {Upcoming.map((item, index) => (
            <EachNews
              event={true}
              id={item.id}
              title={item.title}
              description={item.shortDescription}
              buttonTitle={item.buttonTitle}
              imageDisplay={true}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default UpcomingEvents;
