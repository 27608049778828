import { Provider } from "react-redux";
import { createStore } from "redux";
import "./App.css";
import HomePage from "./Screens/HomePage";
import Markets from "./Screens/Markets";
import News from "./Screens/News";
import SingleProject from "./Screens/SingleProject";
import rootReducer from "./Redux/rootReducer";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import Secondscreen from "./Screens/Secondscreen";
import NewsEvents from "./Components/HomePageComponent/NewsEvents";
import Work from "./Screens/Work";
import Aboutus from "./Screens/Aboutus";
import Impact from "./Screens/Impact";
import Contactus from "./Screens/Contactus"
import Ourteam from "./Screens/Ourteam";
import SingleEvents from "./Screens/SingleEvents";
import Navbar from "./Components/GlobalComponent/Navbar";
import Capabilities from "./Screens/Capabilities";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const App = () => {
  const store = createStore(rootReducer);
  return (
    <HelmetProvider>

    <Provider store={store}>
      <Routes>
        <Route path="/"  element={<HomePage />} />
        <Route path="home"  element={<HomePage />} />
        <Route path="impact" element={<Impact />} />
        <Route path="/market/:id/:name/" element={<Markets />} />
        <Route path="/capabilities/:id/:name/" element={<Capabilities />} />

        <Route path="about" element={<Aboutus />} />
        <Route path="/singleProject/:id/:name/" element={<SingleProject />} />
        <Route path="news" element={<News />} />
        <Route path="work" element={<Work />} />
        <Route path="contact" element={<Contactus />} />
        <Route path="ourTeam" element={<Ourteam />} />
        <Route path="/singleEvent/:id/:name/" element={<SingleEvents />} />

      </Routes>
    </Provider>
    </HelmetProvider>
  );
};

export default App;
