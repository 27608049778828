import React, { useEffect, useState } from "react";
import Footer from "../Components/GlobalComponent/Footer";
import HeaderDescription from "../Components/GlobalComponent/HeaderDescription";
import NewsTitle from "../Components/GlobalComponent/NewsTitle";
import "../assets/css/SingleProject.css";
import { colors } from "../Library/colors";
import { city, productImage } from "../Library/icons";
import { fontFamilyRegular } from "../Library/styles";
import "../assets/css/colors.css";
import "../assets/css/Global.css";
import { useLocation, useParams } from "react-router-dom";
import { scrollToTop } from "../Library/functions";
import Loading from "../Components/GlobalComponent/Loading";
import Navbar from "../Components/GlobalComponent/Navbar";
import { get } from "../api/api";
import { getSingleProject } from "../api/url";
import { useSelector } from "react-redux";

const SingleProject = () => {
  const params = useParams();
  const [loaded, setloaded] = useState(false);
  const [data, setdata] = useState({});
  const imageUrl = useSelector((state) => state.imageUrl);

  const getSingleProjectInfo = async () => {
    setloaded(false);
    const info = await get(getSingleProject(params.id)).then((resp) => {
      if (resp.error) {
      } else {
        // console.log(resp.message.data);
        setdata(resp.message.data);
        setloaded(true);
      }
    });
  };
  useEffect(() => {
    getSingleProjectInfo();
  }, [params.id]);
  if (loaded) {
    return (
      <>
        <Navbar />
        <div
          className="imageContainer"
          style={{ backgroundImage: `url(${imageUrl + data.project.image})` }}
        >
          <div
            className="phoneImage"
            style={{
              backgroundImage: `url(${imageUrl + data.project.image})`,
            }}
          ></div>
          <HeaderDescription
            leftBorder={false}
            rightBorder={true}
            topBorder={false}
            bottomBorder={false}
            buttonDisplay={false}
            rules={[]}
            firstTitle=""
            secondTitle={data.project.title}
            description={data.project.content}
          />
        </div>
        <div className="d-flex justify-content-center">
          <NewsTitle title={data.project.secondTitle} />
        </div>
        <div className="HeaderDescription container color-grey defaultFont">
          {data.project.secondContent}
        </div>
        <div className="container my-5 p-0">
          <div
            className="projectItem d-flex align-items-center"
            style={{ backgroundImage: `url(${imageUrl + data.project.image})` }}
          >
            <div
              className="phoneImage"
              style={{
                backgroundImage: `url(${imageUrl + data.project.image})`,
              }}
            ></div>
            <div className="">
              <HeaderDescription
                leftBorder={true}
                rightBorder={false}
                rules={[]}
                topBorder={false}
                bottomBorder={false}
                firstTitle=""
                secondTitle="On the frontlines of 
                  health innovation.
                "
                description="We lead the technology, systems and research that drive better health. Because health agency missions have real life outcomes – providing access to care, advancing medical research and enhancing lives.
  
                From deploying one of the largest clouds in the federal government, to detecting fraud, waste and abuse to protect the Medicare trust fund, researching new breakthroughs in treating traumatic brain injuries and connecting veterans with benefits they have earned."
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <NewsTitle title="Our Approach" />
        </div>
        <div className="container">
          {data.project.features.map((item, index) => (
            <div
              className="row py-2 py-sm-5 "
              style={{ borderBottom: "solid 1px black" }}
            >
              <div className="col-12 col-lg-6 approachTitle color-grey defaultFont">
                {item.title}
              </div>
              <div className="col-12 col-lg-6  approachDescription color-grey defaultFont">
                {item.content}
              </div>
            </div>
          ))}
        </div>
        {data.relatedProjects.map((item, index) => (
         <div className="container my-5 p-0">
         <div
           className="EachItem2 d-flex justify-content-center  justify-content-md-end align-items-end"
           style={{ backgroundImage: `url(${imageUrl+item.image})` }}
         >
           <div
             className="phoneImage"
             style={{
               backgroundImage: `url(${imageUrl+item.image})`,
             }}
           ></div>
           <div className="p-0" style={{ zIndex: 2 }}>
             <HeaderDescription
               leftBorder={true}
               rightBorder={false}
               topBorder={false}
               rules={[]}
               bottomBorder={false}
               buttonDisplay={true}
               buttonTitle="Learn More"
               firstTitle={item.title}
               secondTitle={item.title}
               description={item.shortDescription} />
           </div>
           <div className="backgroundContainer background-lightblue"></div>
         </div>
       </div>
          ))}
        {/* <div className="container my-5 p-0">
          <div
            className="EachItem2 d-flex justify-content-center  justify-content-md-end align-items-end"
            style={{ backgroundImage: `url(${city})` }}
          >
            <div
              className="phoneImage"
              style={{
                backgroundImage: `url(${city})`,
              }}
            ></div>
            <div className="p-0" style={{ zIndex: 2 }}>
              <HeaderDescription
                leftBorder={true}
                rightBorder={false}
                topBorder={false}
                rules={[]}
                bottomBorder={false}
                buttonDisplay={true}
                buttonTitle="Learn More"
                firstTitle="Project 3"
                secondTitle="On the frontlines of 
                  health innovation.
                "
                description="We lead the technology, systems and research that drive better health. Because health agency missions have real life outcomes – providing access to care, advancing medical research and enhancing lives.
  
                From deploying one of the largest clouds in the federal government, to detecting fraud, waste and abuse to protect the Medicare trust fund, researching new breakthroughs in treating traumatic brain injuries and connecting veterans with benefits they have earned."
              />
            </div>
            <div className="backgroundContainer background-lightblue"></div>
          </div>
        </div> */}
        <div className="mt-5">
          <Footer />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
};

export default SingleProject;
