import {
  ABOUTPAGE,
  CAPABILITIES,
  CONTACTINFO,
  CONTACTPAGE,
  FOOTERITEMS,
  HOMEPAGE,
  IMPACTPAGE,
  JOBSPAGE,
  MARKETPAGES,
  MARKETS,
  NEWSPAGE,
} from "./actionTypes";
import {
  aboutPage,
  capabilities,
  contactInfo,
  contactPage,
  footerItems,
  homePage,
  impactPage,
  jobsPage,
  marketPages,
  markets,
  newsPage,
} from "./auth";
import { initialState } from "./states";

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case HOMEPAGE:
      return homePage(state, action);
    case ABOUTPAGE:
      return aboutPage(state, action);
    case CONTACTPAGE:
      return contactPage(state, action);
    case IMPACTPAGE:
      return impactPage(state, action);
    case JOBSPAGE:
      return jobsPage(state, action);
    case MARKETPAGES:
      return marketPages(state, action);
    case NEWSPAGE:
      return newsPage(state, action);
    case FOOTERITEMS:
      return footerItems(state, action);
    case MARKETS:
      return markets(state, action);
    case CAPABILITIES:
      return capabilities(state, action);
    case CONTACTINFO:
      return contactInfo(state, action);
    default:
      return state;
  }
};
export default rootReducer;
