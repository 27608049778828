import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { get } from "../api/api";
import { getSingleNews } from "../api/url";
import Footer from "../Components/GlobalComponent/Footer";
import HeaderDescription from "../Components/GlobalComponent/HeaderDescription";
import Loading from "../Components/GlobalComponent/Loading";
import Navbar from "../Components/GlobalComponent/Navbar";

const SingleEvents = () => {
  const params = useParams();
  console.log(params.id)
  const [loaded, setloaded] = useState(false);
  const [info, setinfo] = useState({});
  const imageUrl = useSelector((state) => state.imageUrl);
  const getevent = async () => {
    setloaded(false);
    const info = await get(getSingleNews(params.id)).then((resp) => {
      console.log(resp);
      if (resp.error) {
      } else {
        setinfo(resp.message.data.new);
        setloaded(true);
      }
    });
  };
  useEffect(() => {
    getevent();
  }, [params.id]);
  console.log(info);
  if (loaded) {
    return (
      <>
        <Navbar />
        <div
          className="imageContainer4"
          style={{
            backgroundImage: `url(${imageUrl + info.image})`,
          }}
        >
          <div
            className="phoneImage"
            style={{
              backgroundImage: `url(${imageUrl + info.image})`,
            }}
          ></div>
          <HeaderDescription
            leftBorder={true}
            rightBorder={false}
            topBorder={false}
            bottomBorder={false}
            rules={[]}
            firstTitle=""
            secondTitle={info.title}
            description={info.shortDescription}
          />
        </div>
        <div className="my-5">
          <div className="HeaderDescription container color-grey defaultFont">
            {info.content}
          </div>
        </div>
        <div className="mt-5">
          <Footer />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
};

export default SingleEvents;
