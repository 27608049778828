import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { post } from "../api/api";
import { contactform } from "../api/url";
import "../assets/css/Contactus.css";
import Footer from "../Components/GlobalComponent/Footer";
import HeaderDescription from "../Components/GlobalComponent/HeaderDescription";
import Loading from "../Components/GlobalComponent/Loading";
import Navbar from "../Components/GlobalComponent/Navbar";
import { scrollToTop } from "../Library/functions";
import { productImage } from "../Library/icons";
const Contactus = () => {
  const [loaded, setloaded] = useState(false);
  const SubmitForm = async () => {
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const topic = document.getElementById("subject").value;
    const content = document.getElementById("message").value;
    if (name == "" || email == "" || topic == "" || content == "") {
      alert("Please fill all the fields");
    } else {
      let message = await post(contactform(), {
        name: name,
        email: email,
        content: content,
        subject: topic,
      }).then((resp) => {
        console.log(resp);
        if (resp.error) {
        } else {
          alert("Contact Form Successful");
        }
      });
    }
  };
  useEffect(() => {
    setloaded(true)
  }, []);
  if (loaded) {
    return (
      <div>
                <Helmet>
          <title>Contact Us</title>
        </Helmet>
        <Navbar />
        <div className="imageHeaderContainer">
          <div
            className="imageContainer5"
            style={{ backgroundImage: `url(${productImage})` }}
          ></div>
          <div
            className="phoneImage"
            style={{
              backgroundImage: `url(${productImage})`,
            }}
          ></div>
          <div className="position-absolute" style={{ bottom: 60 }}>
            <HeaderDescription
              leftBorder={false}
              rightBorder={false}
              topBorder={false}
              bottomBorder={true}
              textalignment={true}
              rules={[]}
              firstTitle=""
              secondTitle="Contact Us"
              description="Every day, government agencies face big questions. These questions take big thinking, from both humans and machines.
            "
            />
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
              <h2 className="form-title">Get in Touch</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              {/* <form
                id="contact-form"
                name="myForm"
                className="form"
                // action="#"
                onsubmit="return validateForm()"
                // method="POST"
                role="form"
                onSubmit={()=>{SubmitForm()}}
              > */}
              <div className="form-group">
                <label className="form-label" id="nameLabel" for="name"></label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder=" name"
                  tabindex="1"
                />
              </div>

              <div className="form-group">
                <label
                  className="form-label"
                  id="emailLabel"
                  for="email"
                ></label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder=" Email"
                  tabindex="2"
                />
              </div>

              <div className="form-group">
                <label
                  className="form-label"
                  id="subjectLabel"
                  for="sublect"
                ></label>
                <input
                  type="text"
                  className="form-control"
                  id="subject"
                  name="subject"
                  placeholder="Select Topic"
                  tabindex="3"
                />
              </div>

              <div className="form-group">
                <label
                  className="form-label"
                  id="messageLabel"
                  for="message"
                ></label>
                <textarea
                  rows="6"
                  cols="60"
                  name="message"
                  className="form-control"
                  id="message"
                  placeholder="Your message"
                  tabindex="4"
                ></textarea>
              </div>

              <div className="text-center margin-top-25">
                <button
                  type="submit"
                  className="btn btn-mod btn-border btn-large"
                  onClick={() => {
                    SubmitForm();
                  }}
                >
                  submit
                </button>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
        <div className="mt-5">
          <Footer />
        </div>
      </div>
    );
  } else {
    return <Loading />;
  }
};

export default Contactus;
