import { updateObject } from "./utility";
const homePage = (state, action) => {
  return updateObject(state, { homePage: action.value });
};
const aboutPage = (state, action) => {
  return updateObject(state, { aboutPage: action.value });
};
const contactPage = (state, action) => {
  return updateObject(state, { contactPage: action.value });
};
const impactPage = (state, action) => {
  return updateObject(state, { impactPage: action.value });
};
const jobsPage = (state, action) => {
  return updateObject(state, { jobsPage: action.value });
};
const marketPages = (state, action) => {
  return updateObject(state, { marketPages: action.value });
};
const newsPage = (state, action) => {
  return updateObject(state, { newsPage: action.value });
};
const footerItems = (state, action) => {
  return updateObject(state, { footerItems: action.value });
};

const capabilities = (state, action) => {
  return updateObject(state, { capabilities: action.value });
};
const markets = (state, action) => {
  return updateObject(state, { markets: action.value });
};
const contactInfo = (state, action) => {
  return updateObject(state, { contactInfo: action.value });
};
export {
  homePage,
  aboutPage,
  contactPage,
  impactPage,
  jobsPage,
  marketPages,
  newsPage,
  footerItems,
  capabilities,
  markets,
  contactInfo
};
