import React, { useEffect, useState } from "react";
import Footer from "../Components/GlobalComponent/Footer";
import HeaderDescription from "../Components/GlobalComponent/HeaderDescription";
import NewsTitle from "../Components/GlobalComponent/NewsTitle";
import EachNews from "../Components/News/EachNews";
import "../assets/css/Work.css";
import { productImage } from "../Library/icons";
import { useLocation } from "react-router-dom";
import { scrollToTop } from "../Library/functions";
import Loading from "../Components/GlobalComponent/Loading";
import Navbar from "../Components/GlobalComponent/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { getJobs } from "../api/url";
import { get } from "../api/api";
import { JOBSPAGE } from "../Redux/actionTypes";
import { Helmet } from "react-helmet-async";
const Work = () => {
  const params = useLocation();
  const [loaded, setloaded] = useState(false);
  const dispatch = useDispatch();
  const jobsPage = useSelector((state) => state.jobsPage);
  const imageUrl = useSelector((state) => state.imageUrl);
  const getJobsInfo = async () => {
    const news = await get(getJobs()).then((resp) => {
      console.log(resp);
      if (resp.error) {
      } else {
        dispatch({ type: JOBSPAGE, value: resp.message.data });
        setloaded(true);
      }
    });
  };
  useEffect(() => {
    getJobsInfo();
  }, []);
  if (loaded) {
    return (
      <>
        <Helmet>
          <title>Careers</title>
        </Helmet>
        <Navbar />
        <div className="imageHeaderContainer">
          <div
            className="imageContainer5"
            style={{
              backgroundImage: `url(${
                imageUrl + jobsPage.pages.slider_jobs.image
              })`,
            }}
          ></div>
          <div
            className="phoneImage"
            style={{
              backgroundImage: `url(${
                imageUrl + jobsPage.pages.slider_jobs.image
              })`,
            }}
          ></div>
          <div className="position-absolute" style={{ bottom: 60 }}>
            <HeaderDescription
              leftBorder={false}
              rightBorder={false}
              topBorder={true}
              bottomBorder={false}
              rules={[]}
              firstTitle=""
              secondTitle={jobsPage.pages.slider_jobs.title}
              description={jobsPage.pages.slider_jobs.shortDescription}
            />
          </div>
        </div>
        <div className=" d-flex justify-content-center">
          <NewsTitle title="Open Positions" />
        </div>
        <div className="">
          <div className="container">
            <div className="row">
              {jobsPage.jobs.map((item, index) => (
                <EachNews
                  event={false}
                  title={item.title}
                  description={item.shortDescription}
                  buttonTitle={item.buttonTitle}
                  imageDisplay={false}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="mt-5">
          <Footer />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
};

export default Work;
