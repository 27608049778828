import React, { useEffect, useState } from "react";
import "../../assets/css/OurImpact.css";
import { EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../assets/css/Global.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import GeneralTitle from "../GlobalComponent/GeneralTitle";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const OurImpact = ({ impacts }) => {
  const [activeSlide, setactiveSlide] = useState(0);
  const [slideItem, setslideItem] = useState(3.5);
  const imageUrl = useSelector((state) => state.imageUrl);
  const handleResize = () => {
    if (window.innerWidth < 992 && window.innerWidth >= 576) {
      setslideItem(2);
    } else {
      if (window.innerWidth < 576) {
        setslideItem(1);
      } else {
        setslideItem(3.5);
      }
    }
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });
  useEffect(() => {
    if (window.innerWidth < 992 && window.innerWidth >= 576) {
      setslideItem(2);
    } else {
      if (window.innerWidth < 576) {
        setslideItem(1);
      } else {
        setslideItem(3.5);
      }
    }
  }, []);
  return (
    <>
      <div className=" d-flex flex-column my-5">
        <div className="d-flex justify-content-center">
          <GeneralTitle Title="OUR IMPACT" />
        </div>
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={slideItem}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          navigation={true}
          // pagination={true}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="impactSwiper"
          onSnapIndexChange={(resp) => {
            // console.log(resp)
            setactiveSlide(resp.activeIndex);
          }}
        >
          {impacts.map((item,index) => {
            return (
              
              <SwiperSlide>
                <Link to={"/singleProject/" + item.id + "/" +item.title}>
                <div
                  className="p-3  d-flex flex-column"
                  key={index}
                  style={{
                    backgroundColor:
                      activeSlide == index ? "#D9D9D9" : "transparent",
                      minHeight:550
                  }}
                >
                  <div style={{ width: "100%", height: 300 }}>
                    <img
                      src={imageUrl + item.image}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div className="d-flex flex-column py-2">
                    <div
                      className="impactTitle defaultFont color-darkgrey"
                      style={{ display: activeSlide == index ? "flex" : "none" }}
                    >
                      {item.title}
                    </div>
                    <div
                      className="impactdescription defaultFont color-grey mt-2 "
                      style={{ display: activeSlide == index ? "flex" : "none" }}
                    >
                      {item.shortDescription}
                    </div>
                  </div>
                </div>
              </Link>
              </SwiperSlide>
            );
          })}

        </Swiper>
      </div>
    </>
  );
};

export default OurImpact;
