import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { get } from "../api/api";
import { getTeam } from "../api/url";
import Footer from "../Components/GlobalComponent/Footer";
import GeneralTitle from "../Components/GlobalComponent/GeneralTitle";
import HeaderDescription from "../Components/GlobalComponent/HeaderDescription";
import Loading from "../Components/GlobalComponent/Loading";
import Navbar from "../Components/GlobalComponent/Navbar";
import { placeholder, productImage } from "../Library/icons";

const Ourteam = () => {
  const [loaded, setloaded] = useState(false);
  const [data, setData] = useState({});
  const imageUrl = useSelector((state) => state.imageUrl);
  const Team = async () => {
    setloaded(false);
    const info = await get(getTeam()).then((resp) => {
      if (resp.error) {
      } else {
        setData(resp.message.data);
        setloaded(true);
      }
    });
  };
  useEffect(() => {
    Team();
  }, []);
  if (loaded) {
    return (
      <>
                      <Helmet>
          <title>Our Team</title>
        </Helmet>
        <Navbar />
        <div
          className="imagecontainer3"
          style={{
            backgroundImage: `url(${imageUrl + data.pages.slider_teams.image})`,
          }}
        >
          <div
            className="phoneImage"
            style={{
              backgroundImage: `url(${
                imageUrl + data.pages.slider_teams.image
              })`,
            }}
          ></div>
          <HeaderDescription
            leftBorder={true}
            rightBorder={false}
            topBorder={false}
            bottomBorder={false}
            buttonDisplay={false}
            rules={[]}
            firstTitle=""
            secondTitle={data.pages.slider_teams.title}
            description={data.pages.slider_teams.shortDescription}
          />
        </div>
        <div className="my-5">
          <div className="d-flex justify-content-center">
            <GeneralTitle Title="LEADERSHIP" />
          </div>
          <div className="container">
            <div className="row">
              {data.leaders.map((item, index) => {
                return (
                  <div className="col-12 col-sm-6 col-lg-4 p-2" key={index}>
                    <img src={imageUrl + item.image} className="w-100" />
                    <div className="d-flex justify-content-center py-2 leaderTitle">
                      {item.title}
                    </div>
                    <div className="d-flex justify-content-center py-2 leaderDescription">
                      {item.shortDescription}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="my-5">
          <div className="d-flex justify-content-center">
            <GeneralTitle Title="OUR TEAM" />
          </div>
          <div className="container">
            <div className="row">
              {data.teams.map((item, index) => {
                return (
                  <div
                    className="col-12 col-sm-6 col-lg-4 p-2 d-flex flex-column"
                    key={index}
                  >
                    <img src={imageUrl + item.image} className="w-100" />
                    <div className="d-flex justify-content-center py-2 leaderTitle">
                      {item.title}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="mt-5">
          <Footer />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
};

export default Ourteam;
