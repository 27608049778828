import React from "react";
import "../../assets/css/Links.css";
import { colors } from "../../Library/colors";
import { fontFamilyRegular } from "../../Library/styles";
import "../../assets/css/colors.css";
import "../../assets/css/Global.css";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const Links = ({ pages }) => {
  const imageUrl = useSelector((state) => state.imageUrl);
  return (
    <div className=" container  my-5">
      <div className="row">

        <div className="col-12 col-md-6 col-xl-4 d-flex my-2 justify-content-center">
        <Link to={'/work/'}>
          <div
            className="linkItems p-4 d-flex flex-column justify-content-end"
            style={{
              backgroundImage: `url(${imageUrl + pages.homepage_work.image})`,
            }}
          >
            <div className="linkTitle color-white">
              {pages.homepage_work.title}
            </div>
            <div className="linkDescriptionContainer">
              <div className="linkDescription color-darkgrey defaultFont">
                {pages.homepage_work.shortDescription}
              </div>
              <div className="mt-2 d-flex align-items-center color-darkgrey defaultFont ">
                <div className="linkDescription">
                  {pages.homepage_work.buttonTitle}
                </div>
                <div className="ms-2">
                  <i class="fa fa-light fa-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
        </Link>
        </div>
        <div className="col-12 col-md-6 col-xl-4 d-flex my-2 justify-content-center">
        <Link to={'/contact/'}>
          <div
            className="linkItems p-4 d-flex flex-column justify-content-end"
            style={{
              backgroundImage: `url(${
                imageUrl + pages.homepage_contact.image
              })`,
            }}
          >
            <div className="linkTitle color-white">
              {pages.homepage_contact.title}
            </div>
            <div className="linkDescriptionContainer">
              <div className="linkDescription color-darkgrey defaultFont">
                {pages.homepage_contact.shortDescription}
              </div>
              <div className="mt-2 d-flex align-items-center ">
                <div className="linkDescription color-darkgrey defaultFont">
                  {pages.homepage_contact.buttonTitle}
                </div>
                <div className="ms-2">
                  <i class="fa fa-light fa-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
          </Link>
        </div>
        <div className="col-12 col-md-6 col-xl-4 d-flex my-2 justify-content-center">
        <Link to={'/contact/'}>

          <div
            className="linkItems p-4 d-flex flex-column justify-content-end"
            style={{
              backgroundImage: `url(${imageUrl + pages.homepage_lab.image})`,
            }}
          >
            <div className="linkTitle color-white">
              {pages.homepage_lab.title}
            </div>
            <div className="linkDescriptionContainer">
              <div className="linkDescription color-darkgrey defaultFont">
                {pages.homepage_lab.shortDescription}
              </div>
              <div className="mt-2 d-flex align-items-center ">
                <div className="linkDescription color-darkgrey defaultFont">
                  {pages.homepage_lab.buttonTitle}
                </div>
                <div className="ms-2">
                  <i class="fa fa-light fa-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
        </Link>
        </div>
      </div>
    </div>
  );
};

export default Links;
