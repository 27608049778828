import React, { useEffect, useState } from "react";
import "../../assets/css/Footer.css";
import "../../assets/css/colors.css";
import { fontFamilyRegular } from "../../Library/styles";
import { Logo } from "../../Library/icons";
import { Navigate, Link, useNavigate, redirect } from "react-router-dom";
import { getFooter } from "../../api/url";
import { get } from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { CONTACTINFO } from "../../Redux/actionTypes";
const Footer = () => {
  const navigate = useNavigate();
  const title = "hdksahkjdhska";
  const markets = useSelector((state) => state.markets);
  const capabilities = useSelector((state) => state.capabilities);
  const contactInfo = useSelector((state) => state.contactInfo);

  // const [contacts, setcontacts] = useState([]);
  const dispatch = useDispatch();

  const getFooterInfo = async () => {
    let info = await get(getFooter()).then((resp) => {
      if (resp.error) {
      } else {
        dispatch({ type: CONTACTINFO, value: resp.message.data.contactInfo });
        // setcontacts(resp.message.data.contactInfo);
      }
    });
  };
  useEffect(() => {
    getFooterInfo();
  }, []);
  return (
    <div className="d-flex flex-column FooterContainer background-whitegrey">
      <div className="mainContainer row d-flex justify-content-xl-around justify-content-center justify-content-md-start ">
        <div className="col-12 col-sm-6 col-md-4 col-xl-2 mt-2">
          <div className="p-2 itemContainer">
            <div className="footerHeaderTitle color-darkgrey mb-1">Market</div>
            <div className="d-flex flex-column">
              {markets.map((item, index) => (
                <Link to={"/market/" + item.id + "/" + item.title}>
                  <div key={index} className="footerSubTitle">
                    {item.title}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-xl-2 mt-2">
          <div className="p-2 itemContainer">
            <div
              className="footerHeaderTitle color-darkgrey mb-1"
              style={{ fontFamily: fontFamilyRegular }}
            >
              Capabilities
            </div>
            <div className="d-flex flex-column">
              {capabilities.map((item, index) => (
                <Link to={"/capabilities/" + item.id + "/" + item.title}>
                  <div key={index} className="footerSubTitle">
                    {item.title}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-xl-2 mt-2">
          <div className="p-2 itemContainer">
            <div
              className="footerHeaderTitle color-darkgrey mb-1"
              style={{ fontFamily: fontFamilyRegular }}
            >
              About Genesys
            </div>
            <div className="d-flex flex-column">
              <Link to={"/about"}>
                <div className="footerSubTitle">Our Story</div>
              </Link>
              <Link to={"/ourTeam"}>
                <div className="footerSubTitle">Our Team</div>
              </Link>
              <Link to={"/about"}>
                <div className="footerSubTitle">Our Culture</div>
              </Link>
              <Link to={"/about"}>
                <div className="footerSubTitle">Our Strategy</div>
              </Link>
              <Link to={"/impact"}>
                <div className="footerSubTitle">Impact</div>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-xl-2 mt-2">
          <div className="p-2 itemContainer">
            <div
              className="footerHeaderTitle color-darkgrey mb-1"
              style={{ fontFamily: fontFamilyRegular }}
            >
              Contact
            </div>
            <div className="d-flex flex-column">
              <Link to={"/impact"}>
                <div className="footerSubTitle">Careers</div>
              </Link>
              <Link to={"/contact"}>
                <div className="footerSubTitle">Contact Us</div>
              </Link>
              <Link to={"/news"}>
                <div className="footerSubTitle">News</div>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-xl-2 mt-2 p-0">
          <div className="footerLogo m-auto m-sm-0">
            <img src={Logo} className="Logo" />
          </div>
          <div className=" mt-4">
            <div className="d-flex justify-content-center justify-content-sm-start">
              <div className="me-2">
                <i class="fa fa-light fa-location-dot"></i>
              </div>
              <div className="websiteInfo">{contactInfo.address}</div>
            </div>
          </div>
          <div className=" mt-4">
            <div className="d-flex justify-content-center justify-content-sm-start">
              <div className="me-2">
                <i class="fa-solid fa-phone-volume"></i>
              </div>
              <div className="websiteInfo">{contactInfo.phone}</div>
            </div>
          </div>
          <div className=" mt-4">
            <div className="d-flex justify-content-center justify-content-sm-start">
              <div className="me-2">
                <i class="fa-solid fa-envelope"></i>
              </div>
              <div className="websiteInfo">{contactInfo.email}</div>
            </div>
          </div>
          <div className="d-flex mt-4 ps-sm-4 justify-content-center justify-content-sm-start">
            <a
              href={contactInfo.Facebook}
              target="_blank"
              className="me-3 color-black"
            >
              <i class="fa-brands fa-facebook"></i>
            </a>
            <a
              href={contactInfo.Youtube}
              target="_blank"
              className="me-3 color-black"
            >
              <i class="fa-brands fa-youtube"></i>
            </a>
            <a
              href={contactInfo.LinkedIn}
              target="_blank"
              className="me-3 color-black"
            >
              <i class="fa-brands fa-linkedin-in"></i>
            </a>
            <a
              href={contactInfo.Instagram}
              target="_blank"
              className="me-3 color-black"
            >
              <i class="fa-brands fa-instagram"></i>
            </a>
            <a
              href={contactInfo.Pinterest}
              target="_blank"
              className="me-3 color-black"
            >
              <i class="fa-brands fa-pinterest-p"></i>{" "}
            </a>
          </div>
        </div>
      </div>
      <div className="copyRight d-flex justify-content-center align-items-center background-darkgrey">
        <div
          className="copyRightText color-white"
          style={{ fontFamily: fontFamilyRegular }}
        >
          2022 Genesys Impact LLC - Tailored By Syncus
        </div>
      </div>
    </div>
  );
};

export default Footer;
