import React from "react";
import "../../assets/css/Global.css";
import { colors } from "../../Library/colors";
import '../../assets/css/colors.css'
import { fontFamilyRegular } from "../../Library/styles";
const GeneralTitle = ({ Title }) => {
  return (
    <>
      <div>
        <p className="title color-darkgrey">{Title.slice(0, Title.length - 1)}</p>
        <span className="title ps-2 color-white background-lightblue" style={{width:60, }}>
          {Title.slice(Title.length - 1, Title.length)}
        </span>
      </div>
    </>
  );
};

export default GeneralTitle;
