import React from "react";
import { useSelector } from "react-redux";
import "../../assets/css/OurStory.css";

const OurStory = ({ ourStory }) => {
  // console.log(ourStory)
  const imageUrl = useSelector((state) => state.imageUrl);
  return (
    <div
      class="parallax pb-5 pt-5 p-lg-5 "
      style={{
        backgroundImage: `url(${imageUrl + ourStory.image})`,
      }}
    >
      <h1>{ourStory.title}</h1>
      <p className="p-lg-3 p-sm-3">
        {ourStory.shortDescription}
        <br></br>
        <a href="">Meet the team</a>
      </p>
    </div>
  );
};

export default OurStory;
